import {Injectable} from "@angular/core";
import {SnotifireService} from "ngx-snotifire";

@Injectable()
export class NotificationsFacade {

  constructor(private notifications: SnotifireService) {
  }

  public success(title: string, body: string) {
    this.notifications.success(body, title);
  }

  public info(title: string, body: string) {
    this.notifications.info(body, title);
  }

  public warn(title: string, body: string) {
    this.notifications.warning(body, title);
  }


  public error(title: string, body: string) {
    this.notifications.error(body, title);
  }
}
