// Allowed roles in app
export enum Roles {
  ADMIN = "ADMIN",
  TEACHER = "TEACHER",
}

// Maps a role name to a swedish name
const roleMap = {
  ADMIN: "Administratör",
  TEACHER: "Lärare",
};

// Translate role to swedish
export function translateRole(name: string): string {
  return roleMap[name];
}
